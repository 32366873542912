import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ContactForm from './ContactForm';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: 4 * theme.spacing.unit,
    right: 4 * theme.spacing.unit,
  },
});

const ContactUs = ({ classes }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title="Feedback">
        <Fab className={classes.fab} color="secondary" onClick={() => setOpen(true)}>
          <FeedbackIcon />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} >
        <DialogTitle>Share your thoughts with us</DialogTitle>
        <DialogContent>
          <ContactForm />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

ContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactUs);
