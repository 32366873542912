import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Field from './Field';
import LinkToEtherscan from './LinkToEtherscan';
import Donations from './Donations';
import ContactForm from './ContactForm';
import ExpandableBlock from './ExpandableBlock';

const styles = theme => ({
  description: {
    paddingBottom: 4 * theme.spacing.unit,
  },
  contact: {
    paddingTop: 8 * theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  donations: {
    paddingBottom: theme.spacing.unit,
  },
});

const Summary = ({ classes, account, networkName, contractAddress, transaction, name, symbol, decimals, initialSupply, burnable, pausable/*, claimable*/, code, compiler, etherscanVerified }) => {  
  if (!contractAddress) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.description}>
        <Typography variant="body2">
          Congratulations! the token {name} was created successfully.
        </Typography>
      </div>
      <Grid container spacing={24} justify="flex-start">
        <Grid item xs={12} md={4}>
          <Field label="Name">{name}</Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field label="Symbol">{symbol}</Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field label="Address">
            <LinkToEtherscan networkName={networkName} address={contractAddress} />
          </Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field label="Initial supply">{initialSupply}</Field>
        </Grid>
        <Grid item xs={12} md={8}>
          <Field label="Decimals">{decimals}</Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field label="Burnable">{burnable ? 'Yes' : 'No'}</Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field label="Pausable">{pausable ? 'Yes' : 'No'}</Field>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Field label="Claimable">{claimable ? 'Yes' : 'No'}</Field>
        </Grid> */}
        <Divider />
        <Grid item xs={12}>
          <Field label="Deployed by">
            <LinkToEtherscan networkName={networkName} address={account} />
          </Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field label="On the network">{networkName}</Field>
        </Grid>
        <Grid item xs={12} md={8}>
          <Field label="With the transaction">
            <LinkToEtherscan networkName={networkName} address={transaction} isTransaction={true} />
          </Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field label="Verified on Etherscan">
            {etherscanVerified ? 'Yes' : 'No'}            
          </Field>
        </Grid>
        <Grid item xs={12}>
          <ExpandableBlock title="Smart contract" content={code} contentIsCode={true} />
          <ExpandableBlock title="Compiler" content={compiler} contentIsCode={true} />
        </Grid>
      </Grid>
      <div className={classes.contact}>
        <Typography variant="body2" gutterBottom>
          We hope your token creation experience went smoothly. Let us know your thoughts using the contact form below.
        </Typography>
        <ContactForm />
      </div>
      <div className={classes.donations}>
        <Typography variant="body2" gutterBottom>
          If you found the service helpful, please consider making a donation to help us improve it and add new features.
        </Typography>
        <Donations />
      </div>
    </React.Fragment>
  );
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  account: PropTypes.string,
  networkName: PropTypes.string,
  contractAddress: PropTypes.string,
  transaction: PropTypes.string,
  name: PropTypes.string,
  symbol: PropTypes.string,
  decimals: PropTypes.number,
  initialSupply: PropTypes.number,
  burnable: PropTypes.bool,
  pausable: PropTypes.bool,
  // claimable: PropTypes.bool,
  etherscanVerified: PropTypes.bool,
};

export default withStyles(styles)(Summary);
