import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Router }  from 'react-router';
import Routes from './Routes';
import history from './history';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});

ReactDOM.render((
  <MuiThemeProvider theme={theme}>
    <Router history={history}>
      <Routes />
    </Router>
  </MuiThemeProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Analytics
ReactGA.initialize('UA-129001877-3');
history.listen(location => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname + location.search);
});
ReactGA.pageview(window.location.pathname + window.location.search);
