import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  progress: {
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    marginLeft: 2 * theme.spacing.unit,
  },
});

function ButtonWithProgress({ inProgress = false, classes, children, className, color = 'primary', type = 'submit', size = 'large', variant = 'contained', onClick, ...otherProps }) {
  if (inProgress) {
    return (
      <CircularProgress size={24} className={classes.progress} />
    );
  }

  return (
    <Button type={type} variant={variant} color={color} size={size} onClick={onClick} className={className} {...otherProps}>
      {children}
    </Button>
  );
}

ButtonWithProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  inProgress: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default withStyles(styles)(ButtonWithProgress);