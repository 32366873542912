import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import ConnectWithMetamask from './ConnectWithMetamask';
import CustomizeToken from './CustomizeToken';
import DeployTokenWithMetamask from './DeployTokenWithMetamask';
import SmartContractViewer from './SmartContractViewer';
import BeforeStarting from './BeforeStarting';
import Summary from './Summary';
import VerifyOnEtherscan from './VerifyOnEtherscan';
import Feedback from './Feedback';

const analyticsCategory = 'wizard';

const styles = theme => ({

});

const CreateTokenWizard = ({ classes }) => {
  // const [consentGiven, setConsentGiven] = useState(false);
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [decimals, setDecimals] = useState(18);
  const [initialSupply, setInitialSupply] = useState(10000000);
  const [burnable, setBurnable] = useState(false);
  const [pausable, setPausable] = useState(false);
  // const [claimable, setClaimable] = useState(false);
  // const [generated, setGenerated] = useState(false);
  const [code, setCode] = useState('');
  const [bytecode, setBytecode] = useState('');
  const [gas, setGas] = useState(0);
  const [compiler, setCompiler] = useState('');
  const [account, setAccount] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [transaction, setTransaction] = useState('');
  // const [error, setError] = useState('');
  const [optimized, setOptimized] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [etherscanVerified, setEtherscanVerified] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const onConsent = (value) => {
    // setConsentGiven(value);
    setActiveStep(1);
    ReactGA.event({
      category: analyticsCategory,
      action: 'Consent given',
    });
  };

  const onConnect = ({ account, networkName }) => {
    setAccount(account);
    setNetworkName(networkName);
    setActiveStep(2);
    ReactGA.event({
      category: analyticsCategory,
      action: 'Wallet account chosen',
    });
  };

  const onSmartContractGeneration = ({ name, symbol, decimals, initialSupply, burnable, pausable/*, claimable*/, code, bytecode, gas, compiler, optimized }) => {
    // setGenerated(true);
    setName(name);
    setSymbol(symbol);
    setDecimals(decimals);
    setInitialSupply(initialSupply);
    setBurnable(burnable);
    setPausable(pausable);
    // setClaimable(claimable);
    setCode(code);
    setBytecode(bytecode);
    setGas(gas);
    setCompiler(compiler);
    setOptimized(optimized);
    setActiveStep(3);
    ReactGA.event({
      category: analyticsCategory,
      action: 'Contract generated',
    });
  };

  const onError = (message) => {
    // setError(message);
    ReactGA.exception({
      category: analyticsCategory,
      description: message,
    });
  };

  const onReview = () => {
    setActiveStep(4);
    ReactGA.event({
      category: analyticsCategory,
      action: 'Contract reviewed',
    });
  };

  const onDeploy = ({ address, transaction }) => {
    setContractAddress(address);
    setTransaction(transaction);
    setActiveStep(5);
    ReactGA.event({
      category: analyticsCategory,
      action: 'Contract deployed',
    });
  };

  const onEtherscanContractVerification = (verified) => {
    setEtherscanVerified(verified);
    setActiveStep(6);
    ReactGA.event({
      category: analyticsCategory,
      action: verified ? 'Verified on Etherscan' : 'Not verified on Etherscan',
    });
  };

  const onEtherscanContractVerificationSkip = () => {
    setEtherscanVerified(false);
    setActiveStep(6);
    ReactGA.event({
      category: analyticsCategory,
      action: 'Etherscan verification skipped',
    });
  };

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={0}>
          <StepLabel>Getting started</StepLabel>
          <StepContent>
            <BeforeStarting onConsent={onConsent} />
          </StepContent>
        </Step>
        <Step key={1}>
          <StepLabel>Checking your wallet</StepLabel>
          <StepContent>
            <ConnectWithMetamask onConnect={onConnect} feedback={feedback} />
          </StepContent>
        </Step>
        <Step key={2}>
          <StepLabel>Customising the token</StepLabel>
          <StepContent>
            <CustomizeToken onCreate={onSmartContractGeneration} onError={onError} feedback={feedback} />
          </StepContent>
        </Step>
        <Step key={3}>
          <StepLabel>Reviewing the smart contract</StepLabel>
          <StepContent>
            <SmartContractViewer code={code} bytecode={bytecode} compiler={compiler} onReview={onReview} />
          </StepContent>
        </Step>
        <Step key={4}>
          <StepLabel>Deploying it</StepLabel>
          <StepContent>
            <DeployTokenWithMetamask onDeploy={onDeploy} onError={onError} bytecode={bytecode} gas={gas} networkName={networkName} feedback={feedback} />
          </StepContent>
        </Step>
        <Step key={5}>
          <StepLabel>Verifying on Etherscan</StepLabel>
          <StepContent>
            <VerifyOnEtherscan
              onContractVerification={onEtherscanContractVerification}
              onVerificationSkip={onEtherscanContractVerificationSkip}
              code={code}
              networkName={networkName}
              compiler={compiler}
              contractAddress={contractAddress}
              contractName={name}
              optimized={optimized}
              feedback={feedback}
            />
          </StepContent>
        </Step>
        <Step key={6}>
          <StepLabel>Summary</StepLabel>
          <StepContent>
            <Summary
              account={account}
              networkName={networkName}
              contractAddress={contractAddress}
              transaction={transaction}
              name={name}
              symbol={symbol}
              decimals={decimals}
              initialSupply={initialSupply}
              burnable={burnable}
              pausable={pausable}
              // claimable={claimable}
              code={code}
              compiler={compiler}
              etherscanVerified={etherscanVerified}
            />
          </StepContent>
        </Step>
      </Stepper>
      <Feedback onRef={ ref => setFeedback(ref) } />
    </React.Fragment>
  );
}

CreateTokenWizard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateTokenWizard);
