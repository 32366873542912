import apiRequest from './apiRequest';

const environments = {
  mainnet: {
    explorer: 'https://etherscan.io',
    api: {
      url: 'https://api.etherscan.io/api',
      key: 'HTKWZY2CG8GPT9JKQ5EHAPFTCAM4SUI6P3',
    },
  },
  rinkeby: {
    explorer: 'https://rinkeby.etherscan.io',
    api: {
      url: 'https://api-rinkeby.etherscan.io/api',
      key: '',
    },
  },
  ropsten: {
    explorer: 'https://ropsten.etherscan.io',
    api: {
      url: 'https://api-ropsten.etherscan.io/api',
      key: '',
    },
  },
  kovan: {
    explorer: 'https://kovan.etherscan.io',
    api: {
      url: 'https://api-kovan.etherscan.io/api',
      key: '',
    },
  },
};

function isNetworkSupported(networkName) {
  return !!environments[networkName.toLowerCase()];
}

async function verifyEtherscanSmartContract({ networkName, code, compiler, contractAddress, contractName, optimized }) {
  const environment = environments[networkName.toLowerCase()];

  if (!environment) {
    throw new Error('Unsupported environment in Etherscan');
  }

  const response = await apiRequest({
    path: environment.api.url,
    method: 'POST',
    data: {
      apikey: environment.api.key,      
      module: 'contract',
      action: 'verifysourcecode',
      contractaddress: contractAddress,
      sourceCode: code,
      contractname: contractName,
      compilerversion: compiler,   // see http://etherscan.io/solcversions for list of support versions
      optimizationUsed: optimized ? 1 : 0,
    },
  });

  return response;
}

function linkToExplorer({ networkName, address, isTransaction = false }) {
  const environment = environments[networkName.toLowerCase()];
  if (!environment) {
    return null;
  }

  const collection = isTransaction ? 'tx' : 'address';
  return `${environment.explorer}/${collection}/${address}`;
}

export default environments;
export {
  verifyEtherscanSmartContract,
  isNetworkSupported,
  linkToExplorer,
};
