import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import apiRequest from '../utils/apiRequest';
import ButtonWithProgress from './ButtonWithProgress';
import ExternalLink from './ExternalLink';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  buttons: {
    textAlign: 'end',
  },
  description: {
    paddingBottom: 4 * theme.spacing.unit,
  },
});

const CustomizeToken = ({ classes, onCreate, onError, feedback }) => {
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [decimals, setDecimals] = useState(18);
  const [initialSupply, setInitialSupply] = useState(10000000);
  const [burnable, setBurnable] = useState(false);
  const [pausable, setPausable] = useState(false);
  // const [claimable, setClaimable] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  // const [generated, setGenerated] = useState(false);
  // const [error, setError] = useState('');

  const generate = async () => {
    setInProgress(true);
    const settings = {
      name,
      symbol,
      decimals,
      initialSupply,
      burnable,
      pausable,
      // claimable,
    };
    
    try {
      // setError(null);
      const timestamp = Date.now();
      const { code, bytecode, gas, compiler, optimized } = await apiRequest({
        path: '/generateToken',
        method: 'POST',
        data: settings,
      });

      ReactGA.timing({
        category: 'wizard',
        variable: 'contractGeneration',
        value: Date.now() - timestamp,
      });

      feedback && feedback.showSuccessMessage('Smart contract generated!');
      // setGenerated(true);

      onCreate({
        ...settings,
        code,
        bytecode,
        gas,
        compiler,
        optimized,
      });
    } catch (error) {
      // setError('Smart contract generation failed');
      feedback && feedback.showSuccessMessage('Smart contract generation failed');
      onError(error);
    }

    setInProgress(false);
  };

  return (
    <React.Fragment>
      <div className={classes.description}>
        <Typography variant="body2">
          Next, we will generate the smart contract that will be used to create the ERC20 token.
        </Typography>
        <Typography variant="body2">
          If you are unsure about the settings of the token, please check this <ExternalLink link="https://blockgeeks.com/guides/erc20-tokens/">ERC20 guide by Block Geeks</ExternalLink> or use the default options and specify a name and a symbol.
        </Typography>
      </div>
      <form noValidate>
        <Grid container spacing={24} justify="flex-end">
          <Grid item xs={12} md={6}>
            <TextField
              label="Name of the token"
              helperText="This name does not have to be unique, we suggest you use the name of your project without spaces or special characters. e.g. Tokens"
              fullWidth
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Symbol"
              helperText="3/4 character identifier of your token in uppercase. Ideally it should be unique but it does not have to be. e.g. TKN"
              fullWidth
              required
              value={symbol}
              onChange={(event) => setSymbol(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Decimals"
              helperText="Number of decimals in your token. 1$ has 2 decimals (100 cents)"
              fullWidth
              required
              type="number"
              value={decimals}
              onChange={(event) => setDecimals(Number(event.target.value))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberFormat
              customInput={TextField} 
              label="Initial supply"
              helperText="How many tokens would you like to generate initially, you can burn some later but you cannot add new ones"
              fullWidth
              required
              thousandSeparator=" "
              value={initialSupply}
              onValueChange={(values) => setInitialSupply(values.floatValue)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={burnable}
                  onChange={(_, isChecked) => setBurnable(isChecked)}
                />
              }
              label="Burnable: ability to burn tokens (select this option if you are creating the token for a future ICO/STO)"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={pausable}
                  onChange={(_, isChecked) => setPausable(isChecked)}
                />
              }
              label="Pausable: ablity to stop write operations and token exchanges (this option is too invasive but can come handy if there is a bug in the contract or if your exchange is hacked)"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={claimable}
                  onChange={(_, isChecked) => setClaimable(isChecked)}
                />
              }
              label="Claimable: ask the new owner to claim ownership and avoid mistakenly transferring ownership to the wrong address"
            />
          </Grid> */}
          <Grid item xs={12} className={classes.buttons}>
            <ButtonWithProgress disabled={!name || !symbol} inProgress={inProgress} variant="contained" color="primary" size="large" className={classes.button} onClick={generate}>
              Generate
            </ButtonWithProgress>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

CustomizeToken.propTypes = {
  classes: PropTypes.object.isRequired,
  onCreate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  feedback: PropTypes.object,
};

export default withStyles(styles)(CustomizeToken);
