import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import apiRequest from '../utils/apiRequest';
import ButtonWithProgress from './ButtonWithProgress';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  buttons: {
    textAlign: 'end',
  },
  thanks: {
    paddingTop: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
  },
  error: {
    paddingTop: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
    color: red[500],
  },
});

const ContactForm = ({ classes }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const send = async () => {
    setInProgress(true);
    
    try {
      ReactGA.event({
        category: 'contact',
        action: 'Sending feedback',
      });

      await apiRequest({
        path: '/sendFeedback',
        method: 'POST',
        data: {
          email,
          message,
        },
      });

      setSent(true);
    } catch (error) {
      console.error('Unfortunately, we did not manage to send the feedback');
      setError(error.message || 'Unexpected error');
      
      ReactGA.exception({
        description: 'Feedback could not be sent',
      });
    }

    setInProgress(false);
  };

  if (error) {
    return (
      <Typography variant="h6" className={classes.error}>
        We are really sorry, but it seems there is a temporary issue with our API and we couldn't send the feedback.
      </Typography>
    );
  }
  
  if (sent) {
    return (
      <Typography variant="h6" className={classes.thanks}>
        Thank you very much for the feedback <span role="img" aria-label="thumbs up">👍</span>
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <form noValidate>
        <Grid container spacing={24} justify="flex-end">
          <Grid item xs={12}>
            <TextField
              label="Email"
              placeholder="Your email address"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              placeholder="Feedback"
              fullWidth
              required
              multiline
              rowsMax={5}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={classes.buttons}>
            <ButtonWithProgress disabled={!message} inProgress={inProgress} variant="contained" color="primary" size="large" className={classes.button} onClick={send}>
              Send
            </ButtonWithProgress>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
