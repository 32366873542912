import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    padding: theme.spacing.unit,
  },
});

const Field = ({ classes, label, children }) => {
  return (
    <div className={classes.container}>
      <Typography variant="caption">
        {label}
      </Typography>
      <Typography variant="body1">
        {children}
      </Typography>
    </div>
  );
}

Field.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
};

export default withStyles(styles)(Field);
