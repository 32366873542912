import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import getWeb3Instance from '../utils/getWeb3Instance';
import MetamaskButton from './MetamaskButton';
import ButtonWithProgress from './ButtonWithProgress';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  buttons: {
    textAlign: 'end',
    padding: 3 * theme.spacing.unit,
  },
  description: {
    paddingBottom: 4 * theme.spacing.unit,
  },
  moreDetails: {
    padding: theme.spacing.unit * 2,
  },
});

const supportedNetworks = {
  1: 'Mainnet',
  3: 'Ropsten',
  4: 'Rinkeby',
  42: 'Kovan',
};

function getNetworkName(networkId) {
  return supportedNetworks[networkId] || 'Unknown';
}

function getBalance({ web3, account }) {
  return new Promise((resolve, reject) => {
    web3.eth.getBalance(account, (error, balance) => {
      error ? reject(error) : resolve(balance);
    });
  });
}

let web3 = null;

const ConnectWithMetamask = ({ classes, onConnect, feedback }) => {
  const [account, setAccount] = useState('');
  const [balance, setBalance] = useState('');
  const [balanceWarning, setBalanceWarning] = useState('');
  const [networkId, setNetworkId] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const selectAccount = () => {
    onConnect({
      account,
      networkName,
      networkId,
    });
  };
  
  const connect = async () => {
    setInProgress(true);
    web3 = await getWeb3Instance();

    if (!web3) {
      feedback && feedback.showSuccessMessage('Please install metamask or a compatible browser extension');
    } else if (!web3.eth.accounts[0]) {
      feedback && feedback.showSuccessMessage('Make sure you have at least one account enabled in your wallet');
    } else {
      const accountAddress = web3.eth.accounts[0];
      const selectedNetworkId = web3.version.network;
      const selectedNetworkName = getNetworkName(selectedNetworkId);

      setAccount(accountAddress);
      setNetworkId(selectedNetworkId);
      setNetworkName(selectedNetworkName);

      const accountBalance = await getBalance({ web3, account: accountAddress });
      const balanceInEther = web3.fromWei(accountBalance, 'ether').toString();
      const balanceWarning = balanceInEther === '0' ? 'This account has no balance' : '';

      setBalance(`${balanceInEther} ETH`);
      setBalanceWarning(balanceWarning);
    }

    setInProgress(false);
  }

  const networkWarning = networkId && networkId !== '1' ? 'You are currently not using the main network of Ethereum!' : '';
  const metamaskButtonLabel = account ? 'Re-connect' : 'Connect';

  return (
    <React.Fragment>
      <div className={classes.description}>
        <Typography variant="body2">
          Now, we will double check things and make sure that your Metamask wallet is setup correctly and that you have at least 1 active account.
        </Typography>
      </div>
      <Grid container spacing={24} justify="flex-end">
        {account && <React.Fragment>
          <Grid item xs={12} md={4}>
            <TextField
              label="Network"
              variant="outlined"
              disabled
              fullWidth
              value={networkName}
              helperText={networkWarning}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label="Account"
              variant="outlined"
              disabled
              fullWidth
              value={account}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Balance"
              variant="outlined"
              disabled
              error={!!balanceWarning}
              fullWidth
              value={balance}
              helperText={balanceWarning}
            />
          </Grid>
        </React.Fragment>}
        <Grid item xs={12} className={classes.buttons}>
          <MetamaskButton inProgress={inProgress} variant="contained" color="primary" size="large" onClick={connect}>
            {metamaskButtonLabel}
          </MetamaskButton>
          <ButtonWithProgress disabled={!account} variant="contained" color="primary" size="large" className={classes.button} onClick={selectAccount}>
            Continue
          </ButtonWithProgress>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ConnectWithMetamask.propTypes = {
  classes: PropTypes.object.isRequired,
  onConnect: PropTypes.func.isRequired,
  feedback: PropTypes.object,
};

export default withStyles(styles)(ConnectWithMetamask);
