import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NavBar from './NavBar';
import CssBaseline from '@material-ui/core/CssBaseline';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    // overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minWidth: 0, // So the Typography noWrap works
  },
  padding: {
    padding: theme.spacing.unit * 3,
  },
  toolbar: theme.mixins.toolbar,
});

const PageLayout = ({ classes, children, withPadding = true }) => {
  const mainElementClass = withPadding ? classNames(classes.content, classes.padding) : classes.content;
  
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <NavBar />
        <main className={mainElementClass}>
          <div className={classes.toolbar} />
          { children }
        </main>
      </div>
    </React.Fragment>
  )
}

PageLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  withLoginLogoutButton: PropTypes.bool,
  withPadding: PropTypes.bool,
}

export default withStyles(styles)(PageLayout);
