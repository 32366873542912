import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  code: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

const ExpandableBlock = ({ classes, title, content, contentIsCode = false }) => {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {contentIsCode ? <Typography><code className={classes.code}>{content}</code></Typography> : content}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

ExpandableBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  contentIsCode: PropTypes.bool, 
};

export default withStyles(styles)(ExpandableBlock);
