import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CryptoAddress from './CryptoAddress';

const styles = theme => ({
  qrCode: {
    marginTop: 2 * theme.spacing.unit,
    textAlign: 'center',
  },
});

const Donations = ({ classes }) => {
  return (
    <React.Fragment>
      <CryptoAddress label="ETH" address="0xEdc8451036185717D4F11B7e3290364071233872" isDonationAddress={true} />
      <CryptoAddress label="BTC" address="3EUSjYTeLQmnamWLVjz4qch5TB3cdSWXth" isDonationAddress={true} />
      <CryptoAddress label="BCH" address="qqavhajasy6gegxkkjg7p4jcc23z9k5x3sgejr59hl" isDonationAddress={true} />
      <CryptoAddress label="LTC" address="MNjiaHM9EooKHLRVdS3LCr2rAZkmBqXNqA" isDonationAddress={true} />
    </React.Fragment>
  );
}

Donations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Donations);
