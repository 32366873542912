import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import QRCode from 'qrcode.react';
import QrcodeIcon from 'mdi-material-ui/Qrcode';
import ExternalLink from './ExternalLink';

const styles = theme => ({
  qrCodeContainer: {
    margin: 2 * theme.spacing.unit,
    textAlign: 'center',
  },
  qrCode: {
    marginBottom: 2 * theme.spacing.unit,
  },
});

const CryptoAddress = ({ classes, label, address, link, title = 'Scan this QR code with a wallet app', isDonationAddress = false }) => {
  const [open, setOpen] = useState(false);
  const labelWithAddress = label ? `${label}: ${address}` : address;
  const text = link ? <ExternalLink link={link}>labelWithAddress</ExternalLink> : labelWithAddress;

  const showQRCode = () => {
    setOpen(true);

    if (isDonationAddress) {
      ReactGA.event({
        category: 'donation',
        action: 'Displaying QR code',
        label,
      });
    }
  };

  return (
    <React.Fragment>
      <Typography variant="body2">
        {text}
        <Button onClick={showQRCode}>
          <QrcodeIcon />
        </Button>
      </Typography>
      <Dialog open={open} onClose={() => setOpen(false)} >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.qrCodeContainer}>
          <QRCode value={address} renderAs="svg" size={256} className={classes.qrCode} />
          <DialogContentText>
            {labelWithAddress}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

CryptoAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  address: PropTypes.string,
  isDonationAddress: PropTypes.bool,
};

export default withStyles(styles)(CryptoAddress);
