async function getWeb3Instance() {
  const { Web3, ethereum, web3 } = window;
  let web3Instance = null;

  if (ethereum && ethereum.isMetaMask) {
    web3Instance = new Web3(ethereum);
    await ethereum.enable();

  } else if (web3 && web3.currentProvider && web3.currentProvider.isMetaMask) { // legacy
    web3Instance = new Web3(web3.currentProvider);
  }

  return web3Instance;
}

export default getWeb3Instance;