import React from 'react';
import { Route, Switch }  from 'react-router-dom';
import Landing from './pages/Landing';
import CreateToken from './pages/CreateToken';
// import About from './pages/About';
import NotFound from './pages/NotFound';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Landing}/>
    <Route path="/create" component={CreateToken}/>
    {/* <Route path="/about" component={About}/> */}
    <Route path="*" component={NotFound}/>
  </Switch>
);

export default Routes;
