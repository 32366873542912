import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PageLayout from '../components/PageLayout';
import CreateTokenWizard from '../components/CreateTokenWizard';
import ContactUs from '../components/ContactUs';

const styles = theme => ({

});

const CreateToken = ({ classes }) => {
  return (
    <PageLayout>
      <CreateTokenWizard />
      <ContactUs />
    </PageLayout>
  );
}

CreateToken.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateToken);
