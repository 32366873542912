import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExternalLink from './ExternalLink';
import { linkToExplorer } from '../utils/etherscan';

const styles = theme => ({
});

function LinkToEtherscan({ classes, networkName, address, isTransaction = false }) {
  if (!networkName || !address) {
    return null;
  }

  const link = linkToExplorer({ networkName, address, isTransaction });
  if (!link) {
    return null;
  }
  
  return (
    <ExternalLink link={link}>
      {address}
    </ExternalLink>
  );
}

LinkToEtherscan.propTypes = {
  classes: PropTypes.object.isRequired,
  address: PropTypes.string,
  networkName: PropTypes.string,
  isTransaction: PropTypes.bool,
};

export default withStyles(styles)(LinkToEtherscan);