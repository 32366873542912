import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  close: {
    padding: theme.spacing.unit / 2,
  },
});

class Feedback extends React.Component {

  componentDidMount() {
    if (typeof this.props.onRef === 'function') {
      this.props.onRef(this);
    }
  }
  componentWillUnmount() {
    if (typeof this.props.onRef === 'function') {
      this.props.onRef(undefined);
    }
  }

  constructor(props) {
    super(props);
    this.handleMessageFeedbackClose = this.handleMessageFeedbackClose.bind(this);
    this.showSuccessMessage = this.showSuccessMessage.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.state = {
      messageFeedbackOpen: false,
      messageFeedbackError: false,
      defaultSuccessMessage: props.defaultSuccessMessage || 'Done!',
      defaultErrorMessage: props.defaultErrorMessage || 'An error occured and the operation could not be completed',
      successMessage: null,
      errorMessage: null,
    };
  }

  handleMessageFeedbackClose() {
    this.setState({
      messageFeedbackOpen: false,
      messageFeedbackError: false,
      successMessage: null,
      errorMessage: null,
    });
  }
  
  showSuccessMessage(message) {
    this.setState({
      messageFeedbackOpen: true,
      messageFeedbackError: false,
      successMessage: message || this.state.defaultSuccessMessage,
    });
  }

  showErrorMessage(message) {
    this.setState({
      messageFeedbackOpen: true,
      messageFeedbackError: true,
      successMessage: message || this.state.defaultErrorMessage,
    });
  }

  render() {
    const { classes, autoHideDuration = 6000 } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.messageFeedbackOpen}
        autoHideDuration={autoHideDuration}
        onClose={this.handleMessageFeedbackClose}
        ContentProps={{
          'aria-describedby': 'feedback-message-id',
        }}
        message={this.state.messageFeedbackError ? <span id="feedback-message-id">{this.state.errorMessage}</span> : <span id="feedback-message-id">{this.state.successMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleMessageFeedbackClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

Feedback.propTypes = {
  classes: PropTypes.object.isRequired,
  autoHideDuration: PropTypes.number,
};

export default withStyles(styles)(Feedback);