import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ButtonWithProgress from './ButtonWithProgress';
import ExpandableBlock from './ExpandableBlock';
import ExternalLink from './ExternalLink';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  buttons: {
    textAlign: 'end',
  },
  description: {
    paddingBottom: 4 * theme.spacing.unit,
  },
  termsCheckbox: {
    width: '100%',
  },
});

const termsAndConditionsRaw = `
  This is a free service intended to help the community better understand and create tokens on Ethereum, use it at your own risk.
  The code and tokens generated by this website MUST not be used to scam and harm others.
  We do not take any responsibility and we are not liable for any damage caused through use of this service.
  We use cookies (Google Analytics) to better understand how the website is used and to offer better services.
  We do not collect any personal information about our users, and we do not keep a user database.
  We will never ask you to give us any secure information whether it is a password, a mnemonic, or a private key.
  We are not affiliated with the tools and browser plugins used in this website and we may offer alternative wallets and code generators in the future.
  We use code from https://github.com/OpenZeppelin/openzeppelin-solidity to create our smart contract code templates.
  All trademarks and rights are of their respective owners.
`;

const termsAndConditions = (
  <List dense={true}>
    {termsAndConditionsRaw.split('\n').map((line, key) => (
      <ListItem key={key}><ListItemText primary={line} /></ListItem>
    ))}
  </List>
);

const BeforeStarting = ({ classes, onConsent }) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);

  const giveConsent = async () => {
    setConsentGiven(true);
    onConsent(true);
  };

  return (
    <React.Fragment>
      <div className={classes.description}>
        <Typography variant="body2">
          In this wizard, we will guide you through the different steps of ERC20 token creation.
        </Typography>
        <Typography variant="body2" gutterBottom>
          But before we start, please make sure <ExternalLink link="https://metamask.io/">Metamask</ExternalLink> is installed and that you have an account with enough balance (around 0.01 ETH).
        </Typography>
        <Typography variant="body2">
          You will be able see the exact amount in Ether and USD later in Metamask when it is time to deploy the contract.
        </Typography>
        <Typography variant="body2">
          For more details about how to use Metamask, please check this <ExternalLink link="https://cryptospaceguides.com/step-by-step-guide-to-metamask/">guide by Crypto Space Guides</ExternalLink> and <ExternalLink link="https://metamask.zendesk.com">Metamask support pages</ExternalLink>. 
        </Typography>
      </div>

      <Grid container spacing={24} justify="flex-end">
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAgreed}
                onChange={(_, isChecked) => setTermsAgreed(isChecked)}
                disabled={consentGiven}
              />
            }
            label="I agree to the terms and conditions"
            className={classes.termsCheckbox}
          />
          <ExpandableBlock title="Terms and conditions" content={termsAndConditions} />
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <ButtonWithProgress disabled={!termsAgreed} variant="contained" color="primary" size="large" className={classes.button} onClick={giveConsent}>
            Continue
          </ButtonWithProgress>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

BeforeStarting.propTypes = {
  classes: PropTypes.object.isRequired,
  onConsent: PropTypes.func.isRequired,
};

export default withStyles(styles)(BeforeStarting);
