import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PageLayout from '../components/PageLayout';
import ExternalLink from '../components/ExternalLink';
import ContactUs from '../components/ContactUs';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsIcon from '@material-ui/icons/Settings';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    textAlign: 'center',
    paddingTop: 2 * theme.spacing.unit,
    paddingBottom: 0,
  },
  cardContent: {
    flexGrow: 1,
    textAlign: 'center',
  },
  mediaIcon: {
    fontSize: 50,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
    textAlign: 'center',
  },
});

const features = [
  {
    title: 'Easy to use',
    content: 'Wizard to guide you through all the token generation steps',
    icon: PlayArrowIcon,
  },
  {
    title: 'Free',
    content: 'The service is free and you only pay for Ethereum network fees',
    icon: MoneyOffIcon,
  },
  {
    title: 'Secure',
    content: 'Contract deployment is directly managed by your Metamask wallet',
    icon: SecurityIcon,
  },
  {
    title: 'Customizable',
    // content: 'In addition to the basic settings, you can also make your token burnable, pausable, and claimable',
    content: 'In addition to the basic settings, you can also make your token burnable, and pausable',
    icon: SettingsIcon,
  },
];

const Landing = ({ classes }) => {
  return (
    <PageLayout withPadding={false}>
      <div className={classes.heroUnit}>
        <div className={classes.heroContent}>
          <Typography component="h4" variant="h2" align="center" color="textPrimary" gutterBottom>
            Token generator
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" paragraph>
            Wizard for easy ERC20 token creation
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={16} justify="center">
              <Grid item>
                <Button variant="contained" color="primary" component={Link} to="/create">
                  Get started
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div className={classNames(classes.layout, classes.cardGrid)}>
        <Grid container spacing={40}>
          {features.map(({ title, content, icon: CardIcon }) => (
            <Grid item key={title} sm={6} md={4} lg={3}>
              <Card className={classes.card}>
                <div className={classes.cardMedia}>
                  <CardIcon className={classes.mediaIcon} />
                </div>
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {title}
                  </Typography>
                  <Typography>
                    {content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      <footer className={classes.footer}>
        <Typography variant="subtitle2" color="secondary">
          <ExternalLink link="https://www.kawatta.com">
            Kawatta
          </ExternalLink>
        </Typography>
      </footer>
      <ContactUs />
    </PageLayout>
  );
}

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Landing);
