import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ExpandableBlock from './ExpandableBlock';

const styles = theme => ({
  description: {
    paddingBottom: 4 * theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  buttons: {
    marginTop: 2 * theme.spacing.unit,
    textAlign: 'end',
  },
});

const SmartContractViewer = ({ classes, code, bytecode, compiler, onReview }) => {
  if (!code) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.description}>
        <Typography variant="body2">
          This is the smart contract that we generated in the previous step. Feel free to double check it before we deploy it.
        </Typography>
      </div>
      <ExpandableBlock title="Code" content={code} contentIsCode={true} />
      <ExpandableBlock title="Bytecode" content={bytecode} contentIsCode={true} />
      <ExpandableBlock title="Compiler" content={compiler} contentIsCode={true} />
      <Grid container spacing={24} justify="flex-end">
        <Grid item xs={12} className={classes.buttons}>
          <Button variant="contained" color="primary" size="large" className={classes.button} onClick={() => onReview(true)}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

SmartContractViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  bytecode: PropTypes.string,
  compiler: PropTypes.string,
  onReview: PropTypes.func,
};

export default withStyles(styles)(SmartContractViewer);
