import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import ButtonWithProgress from './ButtonWithProgress';
import MetamaskIcon from './MetamaskIcon';

const styles = theme => ({
  metamaskButton: {
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[300],
    },
  },
  metamaskIcon: {
    height: 24,
    marginRight: theme.spacing.unit,
  },
});

const MetamaskButton = ({ classes, ...otherProps }) => {
  const disabled = otherProps.disabled;

  return (
    <ButtonWithProgress className={classes.metamaskButton} {...otherProps}>
      {!disabled && <MetamaskIcon className={classes.metamaskIcon} />}
      {otherProps.children}
    </ButtonWithProgress>
  );
}

MetamaskButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MetamaskButton);
