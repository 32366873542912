import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import getWeb3Instance from '../utils/getWeb3Instance';
import MetamaskButton from './MetamaskButton';
import LinkToEtherscan from './LinkToEtherscan';
import Field from './Field';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  buttons: {
    textAlign: 'end',
    padding: 3 * theme.spacing.unit,
  },
  description: {
    paddingBottom: 4 * theme.spacing.unit,
  },
  moreDetails: {
    padding: theme.spacing.unit * 2,
  },
});

function sendTransaction({ web3, transaction }) {
  return new Promise((resolve, reject) => {
    web3.eth.sendTransaction(transaction, (error, transactionHash) => {
      error ? reject(error) : resolve(transactionHash);
    });
  });
}

function wait(seconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}

function getTransactionReceipt({ web3, transactionHash }) {
  return new Promise((resolve, reject) => {
    web3.eth.getTransactionReceipt(transactionHash, (error, receipt) => {
      error ? reject(error) : resolve(receipt);
    });
  });
}

async function waitForDeployment({ web3, transactionHash }) {
  const receipt = await getTransactionReceipt({ web3, transactionHash });
  if (!receipt) {
    await wait(1);
    return waitForDeployment({ web3, transactionHash });
  }

  return receipt.contractAddress;
}

const DeployTokenWithMetamask = ({ classes, onDeploy, onError, networkName, bytecode, gas, feedback }) => {
  if (!bytecode) {
    return null;
  }
  
  const [address, setAddress] = useState('');
  const [transaction, setTransaction] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  // const [error, setError] = useState(null);

  const cancel = () => {
    setTransaction('');
    setAddress('');
    setCancelled(true);
    setInProgress(false);
  };

  const deploy = async () => {
    setCancelled(false);
    setInProgress(true);

    try {
      // setError(null);
      ReactGA.event({
        category: 'wizard',
        action: 'Deployment button clicked',
      });
      
      const web3 = await getWeb3Instance();
      const transactionHash = await sendTransaction({
        web3,
        transaction: {
          data: bytecode,
          gas,
        },
      });
      
      ReactGA.event({
        category: 'wizard',
        action: 'Transaction generated',
        label: networkName,
      });

      setTransaction(transactionHash);

      const timestamp = Date.now();
      const contractAddress = await waitForDeployment({ web3, transactionHash });

      ReactGA.timing({
        category: 'wizard',
        variable: 'contractDeployment',
        value: Date.now() - timestamp,
      });
 
      if (!cancelled) {
        setAddress(contractAddress);  
        feedback && feedback.showSuccessMessage('Contract deployed!');
        onDeploy({ address: contractAddress, transaction: transactionHash });
      }
    } catch (error) {
      console.error(error);

      if (!cancelled) {
        const errorMessage = 'Deployment failed!';
        // setError(errorMessage);
        feedback && feedback.showSuccessMessage(errorMessage);
        onError(errorMessage);
      }
    }

    setInProgress(false);
  }

  return (
    <React.Fragment>
      <div className={classes.description}>
        <Typography variant="body2">
          Now, we will create the token by deploying the smart contract.
        </Typography>
        <Typography variant="body2">
          If the deployment takes too long, cancel it and re-deploy with a higher gas limit (by editing the Gas fee in Metamask).
        </Typography>
      </div>
      <Grid container spacing={24} justify="flex-end">
        {transaction && <Grid item xs={12}>
          <Field label="Transaction">
            <LinkToEtherscan networkName={networkName} address={transaction} isTransaction={true} />
          </Field>
        </Grid>}
        {address && <Grid item xs={12}>
            <Field label="Address">
              <LinkToEtherscan networkName={networkName} address={address} />
            </Field>
          </Grid>
        }
        <Grid item xs={12} className={classes.buttons}>
          {transaction && !address && <Button variant="text" size="large" color="primary" onClick={cancel} className={classes.button}>
            Cancel
          </Button>}
          {!address && <MetamaskButton inProgress={inProgress} variant="contained" size="large" color="primary" onClick={deploy} className={classes.button}>
            Deploy
          </MetamaskButton>}
          {inProgress && !transaction && <Typography variant="h6" color="textPrimary" gutterBottom>
            Please approve the deployment transaction in Metamask
          </Typography>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

DeployTokenWithMetamask.propTypes = {
  classes: PropTypes.object.isRequired,
  onDeploy: PropTypes.func.isRequired,
  bytecode: PropTypes.string,
  gas: PropTypes.string,
  networkName: PropTypes.string,
  feedback: PropTypes.object,
};

export default withStyles(styles)(DeployTokenWithMetamask);
