import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { verifyEtherscanSmartContract } from '../utils/etherscan';
import ButtonWithProgress from './ButtonWithProgress';
import LinkToEtherscan from './LinkToEtherscan';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  buttons: {
    textAlign: 'end',
    padding: 3 * theme.spacing.unit,
  },
  description: {
    paddingBottom: 4 * theme.spacing.unit,
  },
});

const VerifyOnEtherscan = ({ classes, onContractVerification, onVerificationSkip, networkName, code, compiler, contractAddress, contractName, optimized, feedback }) => {
  const [inProgress, setInProgress] = useState(false);


  const skipVerification = () => {
    onVerificationSkip();
  };

  const verifyContract = async () => {
    setInProgress(true);

    try {
      // setError(null);
      const response = await verifyEtherscanSmartContract({
        networkName,
        code,
        compiler,
        contractAddress,
        contractName,
        optimized,
      });

      if (response.message === 'NOTOK') {
        throw new Error('The contract could not be verified!');
      }

      feedback && feedback.showSuccessMessage('Verified');
      onContractVerification(true);

    } catch (error) {
      console.error(error);
      feedback && feedback.showSuccessMessage('Verification error, please try doing it manually later on Etherscan website');
      onContractVerification(false);
    }

    setInProgress(false);
  }

  return (
    <React.Fragment>
      <div className={classes.description}>
        <Typography variant="body2">
          The contract deployed at <LinkToEtherscan networkName={networkName} address={contractAddress} /> is currently not verified. This means that users cannot confirm that the contract does what it claims to do.
        </Typography>
        <Typography variant="body2">
          In this step, we will verify the contract code on Etherscan, a very popular block explorer, where your users can check your token details and verify its code.
        </Typography>
      </div>
      <Grid container spacing={24} justify="flex-end">
        <Grid item xs={12} className={classes.buttons}>
          <Button variant="text" size="large" color="primary" onClick={skipVerification} className={classes.button}>
            Skip
          </Button>
          <ButtonWithProgress inProgress={inProgress} variant="contained" size="large" color="primary" onClick={verifyContract} className={classes.button}>
            Verify on Etherscan
          </ButtonWithProgress>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

VerifyOnEtherscan.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  networkName: PropTypes.string,
  compiler: PropTypes.string,
  contractAddress: PropTypes.string,
  contractName: PropTypes.string,
  optimized: PropTypes.bool,
  onContractVerification: PropTypes.func,
  feedback: PropTypes.object,
};

export default withStyles(styles)(VerifyOnEtherscan);
