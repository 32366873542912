async function apiRequest({ path, data, method }) {
  const options = {
    method,
    headers: {},
  };

  if (data) {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(data);
    // options.mode = 'cors';
  }

  if (!method) {
    options.method = data ? 'POST' : 'GET'; // use a default method if none was defined 
  }

  const response = await fetch(path, options);

  if (response.status < 200 || response.status >= 300) { // other error
    throw new Error('API request error');
  }

  return response.json();
}

export default apiRequest;
