import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PageLayout from '../components/PageLayout';
import ContactUs from '../components/ContactUs';

const styles = theme => ({
  container: {
    marginTop: 8 * theme.spacing.unit,
  },
});

const NotFound = ({ classes }) => {
  return (
    <PageLayout>
      <div className={classes.container}>
        <Typography variant="h3" align="center" paragraph>
          Oups!
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          The page you are looking couldn't be found
        </Typography>
      </div>
      <ContactUs />
    </PageLayout>
  );
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
