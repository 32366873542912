import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  navBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  titleLink: {
    textDecoration: 'none',
    color: 'white'
  }
});

const NavBar = ({ classes }) => {
  return (
    <React.Fragment>
    <AppBar position="fixed" className={classes.navBar}>
      <Toolbar>
        <Link to="/" className={classes.titleLink}>
          <Typography variant="h6" color="inherit" noWrap>
            Tokens
          </Typography>
        </Link>
        <div className={classes.grow} />
        <Button color="inherit" component={Link} to="/create">Create token</Button>
      </Toolbar>
    </AppBar>
  </React.Fragment>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  withLoginLogoutButton: PropTypes.bool,
};

export default withStyles(styles)(NavBar);
